<template>
  <v-container id="product" fluid tag="section">
    <v-dialog
      v-model="changeRetailDialog"
      persistent
      max-width="600px"
      :retain-focus="false"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Enter New Selling Price</span>
        </v-card-title>
        <v-card-title v-if="productDetail != null">
          <span v-if="productDetail.product_variants.length > 0">
            Product Size {{ variantIndex + 1 }} :
            {{
              productDetail.product_variants[variantIndex]
                .variant_quantity_per_pack +
                "x " +
                productDetail.product_variants[variantIndex]
                  .variant_volume_value +
                " " +
                productDetail.product_variants[variantIndex]
                  .variant_volume_type +
                " " +
                productDetail.product_variants[variantIndex].variant_type
            }}
          </span></v-card-title
        >
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  class="purple-input"
                  label="Current Retail Price"
                  v-model="currentRetailPrice"
                  disabled
                >
                </v-text-field>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  class="purple-input"
                  label="New Retail Price"
                  v-model="newRetalPrice"
                  @keypress="isPriceKey"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="updateRetailPrice"> Update </v-btn>
          <v-btn color="primary" @click="changeRetailDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteVariantDialog" max-width="500px">
      <v-card>
        <v-card-title>Are you sure you want to delete Variants?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            depressed
            @click="deleteVariantDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            color="primary darken-1"
            depressedtext
            @click="deleteVariantsItem"
            >Delete</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="imageDelete" max-width="500px">
      <v-card>
        <v-card-title>Are you sure you want to delete image?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" depressed @click="closeDelete"
            >Cancel</v-btn
          >
          <v-btn color="primary darken-1" depressed @click="deleteItemConfirm"
            >Delete</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-form ref="form">
      <v-container class="py-2" v-if="productDetail != null">
        <v-row>
          <v-col cols="6" md="6">
            <v-text-field
              label="Name"
              class="purple-input"
              v-model="productDetail.name"
              :rules="inputRule"
              :disabled="onlyView"
            />
          </v-col>
          <v-col cols="6" md="6">
            <v-textarea
              class="purple-input"
              label="Description"
              v-model="productDetail.description"
              :rules="inputRule"
              :disabled="onlyView"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="6">
            <v-select
              :items="categories"
              label="Select Category"
              item-text="name"
              item-value="_id"
              v-model="productDetail.category_id"
              @change="getCategoryMargin(productDetail.category_id)"
              :rules="selectRule"
              :disabled="onlyView"
            ></v-select>
          </v-col>
          <v-col cols="6" md="6">
            <v-select
              :items="subCategories"
              label="Select Sub Category"
              item-text="name"
              item-value="_id"
              v-model="productDetail.sub_category_id"
              :rules="selectRule"
              :disabled="onlyView"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="6">
            <v-select
              :items="glugglerSpecials"
              label="Select Gluggler Special"
              item-text="value"
              item-value="_id"
              v-model="productDetail.gluggler_special"
              :disabled="onlyView"
              multiple
            ></v-select>
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field
              class="purple-input"
              label="Brand Name"
              v-model="productDetail.brand_name"
              :rules="inputRule"
              :disabled="onlyView"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="6">
            <v-text-field
              type="number"
              min="0"
              step="1"
              @keypress="isPriceKey"
              class="purple-input"
              label="ABV %"
              v-model="productDetail.alcohol_percentage"
              :disabled="onlyView"
            />
            <h5 class="ABVhint-text">
              Note: Set ABV% 0. If you wish not to show in product detail view
              of customer.
            </h5>
          </v-col>
          <v-col cols="6" md="6">
            <v-file-input
              show-size
              label="Images"
              counter
              truncate-length="15"
              v-model="files"
              :persistent-hint="hint"
              hint="Maximum accepted image size : 50kb"
              :disabled="onlyView"
              accept="image/*"
              @change="checkFileSize()"
            ></v-file-input>
            <ImageErrorDialog
              :error-dialog="errorDialog"
              :error-message="errorMessage"
              @close="errorDialog = false"
            ></ImageErrorDialog>
            <div
              v-if="productDetail.images != null && productDetail.images !== ''"
            >
              <v-col style="padding-left: 30px">
                <v-avatar>
                  <v-img
                    aspect-ratio="1"
                    :src="imageUrl + productDetail.images"
                    @click="openNewTab(imageUrl + productDetail.images)"
                    style="cursor: pointer"
                  />
                </v-avatar>
                <v-btn
                  icon
                  x-small
                  rounded
                  color="error"
                  style="background-color: #fff !important"
                  @click="deleteImage(productDetail.images)"
                >
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </v-col>
            </div>
          </v-col>
        </v-row>
        <v-card>
          <v-card-title>Add Sizes Of Product</v-card-title>
          <v-container
            v-for="(variant, variantIndex) in productDetail.product_variants"
            :key="variant._id"
          >
            <v-card>
              <v-card-title>
                <span>
                  Product Size {{ variantIndex + 1 }} :
                  {{
                    variant.variant_quantity_per_pack +
                      "x " +
                      variant.variant_volume_value +
                      " " +
                      variant.variant_volume_type +
                      " " +
                      variant.variant_type
                  }}
                </span>
                <v-col class="text-right">
                  <v-icon
                    class="mt-6 ml-4"
                    @click="deleteSelectedVariant(variantIndex)"
                    :disabled="onlyView"
                    >mdi-delete</v-icon
                  >
                </v-col>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      type="number"
                      min="1"
                      step="1"
                      class="purple-input"
                      label="Quantity per Pack"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      v-model="variant.variant_quantity_per_pack"
                      :rules="inputRule"
                      :disabled="onlyView"
                    />
                  </v-col>
                  <v-col>
                    <v-select
                      :items="variantTypes"
                      label="Type"
                      v-model="variant.variant_type"
                      :rules="selectRule"
                      :disabled="onlyView"
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-text-field
                      type="number"
                      class="purple-input"
                      label="Size"
                      v-model="variant.variant_volume_value"
                      :rules="inputRule"
                      :disabled="onlyView"
                    />
                  </v-col>
                  <v-col>
                    <v-select
                      :items="variantVolumeTypes"
                      label="Unit"
                      v-model="variant.variant_volume_type"
                      :rules="selectRule"
                      :disabled="onlyView"
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-text-field
                      type="number"
                      min="1"
                      step="1"
                      class="purple-input"
                      label="Club Glugg Points"
                      v-model="variant.variant_club_glugg_points"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      :disabled="onlyView"
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      type="number"
                      min="1"
                      step="1"
                      class="purple-input"
                      label="Max Quantity"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      v-model="variant.variant_max_quantity"
                      :rules="inputRule"
                      :disabled="onlyView"
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      class="purple-input"
                      label="Item Number"
                      v-model="variant.variant_sku_number"
                      :rules="inputRule"
                      :disabled="onlyView"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      class="purple-input"
                      label="UPC Number"
                      v-model="variant.variant_upc_number"
                      :rules="inputRule"
                      :disabled="onlyView"
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      type="number"
                      min="1"
                      step="1"
                      class="purple-input"
                      label="Quantity"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      v-model="variant.variant_quantity"
                      disabled
                    />
                  </v-col>

                  <v-col>
                    <v-text-field
                      class="purple-input"
                      label="Purchase Price"
                      v-model="variant.variant_actual_price"
                      disabled
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      class="purple-input"
                      label="Retail Price"
                      v-model="variant.variant_retail_price"
                      disabled
                    >
                    </v-text-field>
                  </v-col>
                  <v-col v-if="editProduct == true">
                    <v-btn
                      color="primary"
                      @click="retailDialogAlert(variantIndex)"
                    >
                      <v-icon this.changeRetailDialog="true;">
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="2">
                    <v-menu
                      ref="menu"
                      v-model="menu[variantIndex]"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="variant.variant_special_dates"
                          label="Discount Dates"
                          prepend-icon="mdi-calendar"
                          readonly
                          :disabled="onlyView"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="variant.variant_special_dates"
                        range
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col>
                    <v-text-field
                      type="number"
                      value="0.00"
                      @keypress="isPriceKey"
                      class="purple-input"
                      label="Discount(USD)"
                      v-model="variant.variant_special_price"
                      :disabled="onlyView"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-file-input
                      show-size
                      label="Variant Image"
                      counter
                      truncate-length="15"
                      v-model="uploadVariantImage[variantIndex].file"
                      @change="selectVariantFile($event, variantIndex)"
                      :disabled="onlyView"
                      accept="image/*"
                    ></v-file-input>
                    <v-avatar
                      v-if="
                        uploadVariantImage[variantIndex].file != null &&
                          uploadVariantImage[variantIndex].file !== ''
                      "
                    >
                      <v-img
                        aspect-ratio="1"
                        :src="imageUrl + uploadVariantImage[variantIndex].file"
                        @click="
                          openNewTab(
                            imageUrl + uploadVariantImage[variantIndex].file
                          )
                        "
                        style="cursor: pointer"
                      />
                    </v-avatar>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-container>
          <v-card-actions>
            <v-row>
              <v-col cols="12" class="text-right">
                <v-btn
                  width="120px"
                  outlined
                  text
                  type="button"
                  color="primary"
                  class="mr-0"
                  @click="addMoreVariants"
                  :disabled="onlyView"
                  ><v-icon>mdi-plus </v-icon>Add New Size</v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>

        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn
              type="button"
              color="primary"
              class="mr-0"
              @click="submit"
              :loading="loading"
            >
              {{
                onlyView == true
                  ? "Back"
                  : editProduct == true
                  ? "Update"
                  : "Submit"
              }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import ImageErrorDialog from "@/views/dashboard/components/modals/ImageErrorDialog.vue";

export default {
  props: ["id", "action"],
  components: {
    ImageErrorDialog
  },
  data() {
    return {
      inputRule: [v => !!v || "Field is required"],
      selectRule: [v => !!v || "Field is required"],
      multiRule: [v => v.length > 0 || "Field is required"],
      fileuploadSize: [
        v => !v || v.size < 50000 || "Image Size should be less than 50 KB!"
      ],
      variantFileuploadSize: [
        v => !!v || "Field is required",
        v => (v && v.size < 50000) || "Image Size should be less than 50 KB!"
      ],
      imageUrl: process.env.VUE_APP_Image_Link + "Products/",
      loading: false,
      onlyView: false,
      editProduct: false,
      showErrorData: "",
      menu: [],
      files: null,
      productDetail: {
        _id: null,
        name: "",
        description: "",
        category_id: "",
        sub_category_id: "",
        alcohol_percentage: null,
        brand_name: "",
        gluggler_special: [],
        images: "",
        product_variants: []
      },
      variantDetail: {
        _id: null,
        variant_quantity_per_pack: "",
        variant_type: "",
        variant_volume_type: "",
        variant_volume_value: "",
        variant_actual_price: null,
        variant_retail_price: null,
        variant_quantity: null,
        variant_image: null,
        variant_club_glugg_points: null,
        variant_max_quantity: null,
        variant_sku_number: null,
        variant_upc_number: null,
        variant_special_dates: null,
        variant_special_price: 0
      },
      categories: [],
      subCategories: [],
      glugglerSpecials: [],
      variantTypes: ["Keg", "Bottles", "Cans", "Pack"],
      variantVolumeTypes: ["ml", "Liter", "Oz", "Gram", "Cigarettes"],
      margin: null,
      changeRetailDialog: false,
      currentRetailPrice: 0,
      newRetalPrice: 0,
      variantIndex: 0,
      imageDelete: false,
      productImageDelete: null,
      hint: true,
      uploadVariantImage: [],
      removeVariantImage: [],
      deleteVariantDialog: false,
      deleteVariantIndex: null,
      errorDialog: false,
      errorMessage: ""
    };
  },
  computed: {},
  methods: {
    selectVariantFile($event, variantIndex) {
      if ($event != null) {
        this.uploadVariantImage[variantIndex].file = $event;
        this.uploadVariantImage[variantIndex].index = variantIndex;
        this.productDetail.product_variants[variantIndex].variant_image =
          $event.name;
      } else {
        const deleteItem = this.productDetail.product_variants[variantIndex];
        console.log("deleteItem", deleteItem);
        this.uploadVariantImage[variantIndex].file = [];
        this.uploadVariantImage[variantIndex].index = variantIndex;
        this.removeVariantImage.push({
          variant_id: deleteItem._id,
          image_name: deleteItem.variant_image
        });
      }
    },
    isPriceKey(event) {
      const reg = /^-?\d*(\.\d{0,2})?$/;
      let input = event.target.value + String.fromCharCode(event.charCode);
      if (!reg.test(input)) {
        event.preventDefault();
      }
    },
    openNewTab(url) {
      window.open(url, "_blank");
    },
    getCategoryList() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "category/getall")
        .then(response => {
          if (response.status == 200) {
            this.categories = response.data.categories;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    getSubCategoryList(categoryId) {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "product/getSubCategory/" +
            categoryId
        )
        .then(response => {
          if (response.status == 200) {
            this.subCategories = response.data.subCategories;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    getProductDetail(id) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "product/edit/" + id)
        .then(response => {
          if (response.status == 200) {
            this.productDetail = response.data.product;

            this.productDetail.product_variants.forEach(
              (element, variantIndex) => {
                this.uploadVariantImage.push({
                  file: element.variant_image,
                  index: variantIndex
                });
              }
            );
            this.getCategoryMargin(this.productDetail.category_id);
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    getGlugglerSpecials() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "code/gluggler_specials")
        .then(response => {
          if (response.status == 200) {
            this.glugglerSpecials = response.data.codeValue;
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    getCategoryMargin(categoryId) {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "product/getCategoryMargin/" +
            categoryId
        )
        .then(response => {
          if (response.status == 200) {
            this.margin = response.data.categories.margin;
          }
        })
        .catch(error => {
          console.error(error);
        });
      this.getSubCategoryList(categoryId);
    },
    deleteImage(deleteImage) {
      if (this.onlyView == false) {
        this.imageDelete = true;
        this.productImageDelete = deleteImage;
      }
    },
    closeDelete() {
      this.imageDelete = false;
    },
    deleteItemConfirm() {
      let data = { id: this.id, name: this.productImageDelete };
      axios
        .post(process.env.VUE_APP_API_BASE_URL + "product/delete/image", data)
        .then(response => {
          if (response.status == 200) {
            this.closeDelete();
            this.getProductDetail(this.id);
            this.imageDelete = false;
            this.productImageDelete = null;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    deleteSelectedVariant(variantIndex) {
      this.deleteVariantDialog = true;
      this.deleteVariantIndex = variantIndex;
    },
    deleteVariantsItem() {
      const deleteItem = this.productDetail.product_variants[
        this.deleteVariantIndex
      ];
      console.log("deleteItem", deleteItem);
      this.removeVariantImage.push({
        variant_id: deleteItem._id,
        image_name: deleteItem.variant_image
      });
      this.productDetail.product_variants.splice(this.deleteVariantIndex, 1);
      this.deleteVariantIndex = null;
      this.deleteVariantDialog = false;
    },
    submit() {
      console.log(this.productDetail);

      this.showError = false;
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("id", this.productDetail._id);
        formData.append("name", this.productDetail.name);
        formData.append("description", this.productDetail.description);
        formData.append("category_id", this.productDetail.category_id);
        formData.append("sub_category_id", this.productDetail.sub_category_id);
        formData.append(
          "alcohol_percentage",
          this.productDetail.alcohol_percentage
        );
        formData.append(
          "gluggler_special",
          this.productDetail.gluggler_special
        );
        formData.append("brand_name", this.productDetail.brand_name);
        formData.append("images", this.files);
        console.log(this.uploadVariantImage);
        for (let file of this.uploadVariantImage) {
          if (file.file instanceof File) {
            formData.append("variant_images[]", file.file, file.file.name);
          }
        }
        console.log("removeVariantImage", this.removeVariantImage);
        formData.append(
          "variant_remove_images",
          JSON.stringify(this.removeVariantImage)
        );
        formData.append(
          "variants",
          JSON.stringify(this.productDetail.product_variants)
        );
        let storeStock = [];
        let vanStock = [];
        formData.append("store_stock", JSON.stringify(storeStock));
        formData.append("van_stock", JSON.stringify(vanStock));
        this.loading = true;
        if (this.onlyView) {
          this.$router.push({ name: "Products" });
        } else if (this.editProduct == true) {
          if (this.productDetail.images != null || this.files != null) {
            axios
              .post(
                process.env.VUE_APP_API_BASE_URL + "product/update",
                formData
              )
              .then(response => {
                if (response.status == 200) {
                  this.loading = false;
                  this.removeVariantImage = [];
                  this.uploadVariantImage = [];
                  this.files = [];
                  this.productDetail.product_variants = [];
                  this.productDetail.gluggler_special = [];
                  this.productDetail.images = [];
                  this.$router.push({
                    name: "Products",
                    params: { edit: true }
                  });
                }
              })
              .catch(error => {
                this.loading = false;
                if (error.response.data.name != null) {
                  this.showError = true;
                  this.showErrorData = error.response.data.name[0];
                }
                if (error.response.data.images != null) {
                  this.showError = true;
                  this.showErrorData = error.response.data.images[0];
                }
              });
          } else {
            this.errorMessage = `Please choose an image with size less than 50 KB.`;
            this.errorDialog = true;
            this.loading = false;
          }
        } else {
          if (this.files != null) {
            axios
              .post(
                process.env.VUE_APP_API_BASE_URL + "product/create",
                formData
              )
              .then(response => {
                if (response.status == 200) {
                  this.loading = false;
                  this.removeVariantImage = [];
                  this.uploadVariantImage = [];
                  this.files = [];
                  this.productDetail.product_variants = [];
                  this.productDetail.gluggler_special = [];
                  this.productDetail.images = [];
                  this.$router.push({
                    name: "Products",
                    params: { add: true }
                  });
                }
              })
              .catch(error => {
                this.loading = false;
                if (error.response.data.name != null) {
                  this.showError = true;
                  if ((this.showError = true)) {
                    window.scrollTo(0, 0);
                  }
                  this.showErrorData = error.response.data.name[0];
                }
                if (error.response.data.images != null) {
                  this.showError = true;
                  if ((this.showError = true)) {
                    window.scrollTo(0, 0);
                  }
                  this.showErrorData = error.response.data.images[0];
                }
              });
          } else {
            this.errorMessage = `Please choose an image with size less than 50 KB.`;
            this.errorDialog = true;
            this.loading = false;
          }
        }
      }
    },
    updateRetailPrice() {
      let formData = new FormData();
      formData.append("currentPrice", this.currentRetailPrice);
      formData.append("newPrice", this.newRetalPrice);
      formData.append(
        "variant_id",
        this.productDetail.product_variants[this.variantIndex]._id
      );
      formData.append(
        "upc_number",
        this.productDetail.product_variants[this.variantIndex]
          .variant_upc_number
      );
      axios
        .post(
          process.env.VUE_APP_API_BASE_URL + "product/updateRetailPrice",
          formData
        )
        .then(response => {
          if (response.status == 200) {
            this.loading = false;
            this.getProductDetail(this.id);
            this.changeRetailDialog = false;
          }
          if (response.status == 404) {
            this.loading = false;
            this.showError = true;
            this.showErrorData =
              "This product is not in active queue! Add purchase order.";
            this.changeRetailDialog = false;
          }
        })
        .catch(error => {
          this.loading = false;
          this.showError = true;
          this.showErrorData =
            "This product is not in active queue! Add purchase order.";
          this.changeRetailDialog = false;
        });
    },
    retailDialogAlert(variantIndex) {
      this.variantIndex = variantIndex;
      this.changeRetailDialog = true;
      this.currentRetailPrice = this.productDetail.product_variants[
        variantIndex
      ].variant_retail_price;
    },
    addMoreVariants() {
      const variantDetail = {
        _id: null,
        variant_quantity_per_pack: "",
        variant_type: "",
        variant_volume_type: "",
        variant_volume_value: "",
        variant_actual_price: null,
        variant_retail_price: null,
        variant_quantity: null,
        variant_image: null,
        variant_club_glugg_points: null,
        variant_max_quantity: null,
        variant_sku_number: null,
        variant_upc_number: null,
        variant_special_dates: null,
        variant_special_price: 0
      };
      this.uploadVariantImage.push({
        file: [],
        index: this.productDetail.product_variants.length + 1
      });
      this.productDetail.product_variants.push(variantDetail);
    },
    checkFileSize() {
      const maxSizeInBytes = 50 * 1024; // 50kb
      const files = this.files;

      if (files) {
        if (files.size > maxSizeInBytes) {
          // this.errorMessage = `File '${files.name}' exceeds the maximum size limit (50kb).`;
          this.errorMessage = `Please choose an image with size less than 50 KB.`;
          this.errorDialog = true;
          this.files = []; // Clear the selected files
          return;
        }
      }
    }
  },
  mounted() {
    const route = this.$router.currentRoute;
    console.log(route);
    this.getCategoryList();
    this.getGlugglerSpecials();
    this.editProduct = false;
    if (this.id != undefined) {
      this.getProductDetail(this.id);
    }
    if (route.name == "View Product") {
      this.onlyView = true;
    }
    if (route.name == "Edit Product") {
      this.editProduct = true;
    }
  }
};
</script>

<style>
.ABVhint-text {
  color: red;
  font-weight: normal;
  font-size: 11.5px;
  padding-top: 5px;
}
</style>
